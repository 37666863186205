.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn-link{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}

.btn--primary{
    background-color: var(--primary);
    color: var(--dark);
    border: 1px solid var(--primary)
}

.btn--outline{
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile{
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: white;
    padding: 14px 20px;
    border: 1px solid white;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 64px;
    font-size: 20px;
}

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover{
    transition: all 0.3s ease-out;
    background: white;
    color: var(--dark);
}

.btn--wide:hover{
    color: white;
    background-color: var(--red);
    transition: all 0.2s ease-out;
}

.blue {
    background-color: var(--blue);
    color: white;
    border: none;
}

.red {
    background-color: var(--red);
    color: white;
    border: none;
}
.primary {
    background-color: var(--dark);
    color: white;
    border: none;
}
.primary:hover {
    background-color: white;
    color: var(--dark);
}
.green {
    background-color: var(--green);
    color: white;
    border: none;
}
.green:hover {
    background-color: var(--dark);
}