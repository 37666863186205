.plans__section {
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--off-white);
}
  
.plans__container-card {
    background: linear-gradient(45deg, var(--chroma-blue) 0%, var(--blue) 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;
}
  
.plans__container-card:nth-child(2) {
    transform: scale(1.05);
    background: var(--dark);
}
  
.plans__container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: var(--dark-blue);
}
  
.plans__container-card:nth-child(2) {
    margin: 0 24px;
}
  
.plans__container {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.plans__wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
}
  
.plans__heading {
    color: var(--dark-blue);
    margin-bottom: 24px;
}
  
.plans__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: var(--primary);
}
  
.plans__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
}
  
.plans__container-cardInfo h4 {
    font-size: 40px;
}
  
.plans__container-cardInfo p {
    font-size: 14px;
    margin-bottom: 24px;
}
  
.plans__container-features {
    margin: 16px 0 32px;
    list-style: none;
}
  
.plans__container-features li {
    margin-bottom: 10px;
}
  
.plans__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.icon {
    margin: 24px 0;
}
  
@media screen and (max-width: 960px) {
    .plans__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .plans__container-card {
      width: 90%;
    }
  
    .plans__wrapper {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .plans__container-card:nth-child(2) {
      transform: scale(1);
      background: var(--dark);
      margin: 24px;
    }
  
    .plans__container-card:hover {
      transform: none;
    }
}